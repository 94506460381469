@import "variables";
@import "functions";

@font-face {
  font-family: 'Avenir Black Oblique';
  src: url('../assets/fonts/Avenir-BlackOblique.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('../assets/fonts/Avenir-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-HeavyOblique.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('../assets/fonts/Avenir-BookOblique.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-LightOblique.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-MediumOblique.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Oblique.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Roman.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.App {
  font-family: 'Avenir';
  overflow-x: hidden;
}



body {
  font-family: "Avenir", sans-serif;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}




a {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.text-orange {
  color: $orange;
}

svg {
  .text-orange {
    fill: $orange;
  }
}

.text-red {
  color: $red;
}

.text-small {
  font-size: $fontSizeExtraSmall;
}

.text-grey-tiny {
  color: $bordergrey;
  font-weight: $bold;
  font-size: calcRem(12px);
}

.font-size-title {
  font-size: $fontSizeMedium;
}

.font-bold {
  font-weight: $bold;
}

.font-small {
  font-size: $fontSizeExtraSmall !important;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0 !important;
  padding: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
p,
div {
  color: #5d6076;
}

.no-padding {
  padding: 0;
}

.no-decoration {
  text-decoration: none;
}

.container-fluid {
  padding: 0 !important;
}

li {
  list-style: none;
}

.sr-only {
  display: none;
}


.green-circle {
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: $green;
  z-index: 999;
  margin-right: calcRem(10px);
  margin-top: calcRem(5px);
}

.red-circle {
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: $red;
  z-index: 999;
  margin-right: calcRem(10px);
  margin-top: calcRem(5px);
}

.amber-circle {
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: $presoldOrange;
  z-index: 999;
  margin-right: calcRem(10px);
  margin-top: calcRem(5px);
}

.bold {
  font-weight: 500;
}

.orange {
  color: $presoldOrange;
}

#rcc-decline-button {
  background: $red !important;
  border-radius: 10px !important;
  padding: 5px 10px !important;
  font-size: 14px !important;
}

.text-capitalise {
  text-transform: capitalize;
}

.availble-delivery {
  margin-top: 10px;
  border-radius: 15px;
  background-color: $deliverygrey;
  font-size: 0.85rem;
  padding: 8px 15px;
  color: $textgrey;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: flex-start;

  img {
    margin-right: 6px;
  }
}

.mr-2 {
  margin-right: 6px;
}

.mr-3 {
  margin-right: calcRem(10px);
}

.text-black {
  color: $black;
}

.text-grey {
  color: $textgrey;
}

.text-dark-grey {
  color: $darkgrey;
}

.underline {
  text-decoration: underline;
}

.back-icon {
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
  width: 3rem;
  cursor: pointer;
  z-index: 2;
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.hide-desktop {
  display: none;
}

@include media-breakpoint-down(md) {
  .modal.show .modal-dialog {
    margin-top: 10% !important;
    max-height: 80% !important;
  }

  .mob-padd-top {
    padding-top: calcRem(15px);
  }

  .hide-mob {
    display: none !important;
  }

  .hide-desktop {
    display: block;
  }
}

.breadcumb {
  background: $lightergrey;
  padding: calcRem(10px) calcRem(20px);
  margin-bottom: calcRem(15px) !important;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    li {
      list-style: none;
      display: inline-block;
      text-transform: capitalize;

      span,
      a {
        font-size: 0.8rem !important;
        color: #FFF !important;
        text-decoration: none;
        display: block;
        padding: 0 calcRem(5px)
      }
    }
  }
}

.roundcheck {
  fill: $orange;
}

// Buttons
button {}

// Desktop menu
.nav-container {
  .navbarFlex {
    width: 100% !important;
    margin-left: 0 !important;
    justify-content: flex-end !important;

    a.nav-link {
      border-left: 1px solid $lightgrey;
      padding: 0 20px !important;
      margin-right: 0 !important;
      white-space: nowrap;

      &:first-child {
        border-left: 0;
      }

    }

    a.mobile-hide {
      border-left: 0 !important;
      margin-left: 30px;

      .nav-link {
        margin-left: auto !important;
        margin-right: auto !important;
      }

      &~.nav-link {
        border-left: 0;
      }

      &+.nav-link {
        color: $orange !important;
      }

      color: $orange !important;
    }

    a[href*="search"],
    a[href*="cart"] {
      text-decoration: none !important;
      text-align: center !important;

      img {
        padding: 0 !important;
        margin-bottom: calcRem(10px) !important;
      }

      &:after {
        display: block !important;
        font-size: 14px !important;
        content: "Search" !important;
      }
    }

    a[href*="cart"] {
      &:after {
        content: "Basket" !important;
      }
    }
  }
}

// Dashboard and products pages
.hero-section,
.products__header {
  min-height: 0 !important;
  background-color: #f0efec !important;
  width: 100vw !important;
  position: relative !important;
  left: 50% !important;
  margin-bottom: 40px !important;
  margin-left: -50vw !important;
  padding: 10px 0 30px 0 !important;

  .hero-section__title {
    padding-top: 20px;
  }

  h3 {
    font-size: 28px !important;
  }
}

.search-filter {
  max-width: 800px !important;
  margin: 0 auto !important;
  padding-left: 20px !important;
  padding-right: 20px !important;

  .search-filter__bar {
    padding: 0 !important;

    .search-bar {
      padding: 0 !important;
      height: auto !important;
      background-color: transparent !important;

      .search-bar__left {
        height: auto !important;
      }

      .search-bar__right {
        display: none !important;
      }

      .search-bar__left__icon {
        display: none !important;
      }

      input {
        padding: 10px 20px !important;
        background-color: white !important;
        border: 1px solid darken(#f0efec, 10) !important;
      }
    }
  }

  .search-filter__control {
    height: auto !important;

    button {
      border: 1px solid #434975 !important;
      padding: 10px 20px !important;
      height: auto !important;
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }
}

.container.products {
  padding-top: 0 !important;

  .products__header {
    padding-top: calcRem(40px) !important;
    padding-bottom: calcRem(40px) !important;

    h3 {
      text-transform: uppercase !important;
    }
  }
}

.products__row {
  padding-top: calcRem(30px);
}

.categories,
.container.products {
  margin-top: 0 !important;

  .categories__title {
    h3 {
      text-transform: uppercase !important;
    }
  }

  .l-card,
  .l-card img {
    border-radius: 0 0 80px 0 !important;
  }

  .l-card__details {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    padding: calcRem(20px) !important;

    .l-card__details__title {
      padding: 15px 0 !important;
      width: 100% !important;
      background-color: #f9f9f9 !important;
      color: black !important;
      font-size: 18px !important;
      text-align: center !important;
      transition: all 0.25s !important;

      &:hover {
        background-color: #f7f7f7 !important;
        transition: all 0.25s !important;
      }
    }

    button {
      display: none !important;
    }
  }
}

.activities-full {
  margin-bottom: calcRem(60px);
}

.activities,
.activities-full {
  h4 {
    text-transform: uppercase !important;
    color: $titleBlue;
    font-size: 0.7rem;
  }

  h3 {
    text-transform: uppercase !important;
    color: $titleBlue;
  }

  .lw-button {
    padding-left: calcRem(60px);
    padding-right: calcRem(60px);
  }
}

.page-header {
  h1 {
    color: white !important;
  }

  .back-icon {
    display: none !important;
  }
}

/* Login */
.forgot-password-button {
  .lw-button {
    color: $textgrey;
  }
}

.login-container {
  h3 {
    text-transform: uppercase;
  }

  .submit-button {
    padding: 15px;
  }

  .contact-support {
    padding: 0;

    .lw-button {
      text-decoration: underline !important;
      padding-left: 5px !important;
      margin: 0 !important;
      width: auto !important;
      display: inline-block;
      min-width: 0 !important;
    }
  }
}

.lw-button {
  border-radius: 5px;
  ;
}

.page-container input[type="email"],
.page-container input[type="telephone"],
.page-container input[type="text"],
.page-container input[type="password"] {
  border-radius: 0.5rem;
  border-color: #d3d3d3 !important;
}

.categories__title {
  padding-bottom: calcRem(25px);
  font-size: 1.4rem;
}

.category-counts {
  color: $lightergrey;
}

.footer {
  border-top: 2px solid #A4A8B2;
}

.activities-full-list {
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $orange;
  }

}

/** Dashboard **/
.hero-section__title__header {
  font-weight: 500;
  color: $dashboardGrey !important;

  .text-orange {
    color: $dashboardGrey !important;
  }
}

.search-filter__control {
  .lw-button {
    border-radius: 0 !important;
  }
}

.categories__title {
  h3 {
    color: $titleBlue !important;
    padding-bottom: calcRem(15px) !important;
  }
}

.card-order-full-title {
  h2 {
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
}

.card-order-full-summary {
  color: $orange;
}

.categories-cta {
  .lw-button {
    padding-left: calcRem(60px);
    padding-right: calcRem(60px);
  }
}

.grey-container {
  background: #FFF !important;
}

/** My Account **/
.myaccount__content__body__popup {
  background: #F9F9F9;
  border: 0 !important;

  &__warning {
    display: none !important;
  }
}

.account-nav-outer {
  ul {
    margin: 0;
    padding: 0;
  }

  a {
    position: relative;
    color: $textgrey !important;
    font-weight: 500;
    margin-bottom: calcRem(15px) !important;
    font-size: 1.1rem;
    display: block;
    padding-left: 0;

    .pb-4 {
      padding: 0 !important;
    }

    &.active {
      color: $orange !important;

      &::before {
        content: '';
        height: 2px;
        width: calcRem(10px);
        display: block;
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translateY(-50%);
        background: $orange;
      }
    }
  }
}

.myaccount__content__body__top__left__status {
  justify-content: flex-start;
}

.myaccount__content__body__top__left__status__active {
  span {
    display: none;
  }
}

.myaccount__content__body {
  .order-summary {
    background: #F9F9F9;
    border-radius: 5px;
    ;
  }
}

.myaccount-header__logout {
  display: none;
}

.myaccount-header {
  height: 200px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: white !important;
  text-align: left !important;

  .container {
    padding-left: calcRem(40px) !important;
    margin-left: 0 !important;
  }
}

.contact-bottom {
  background: #F9F9F9;
  margin-top: 50px;

  .lw-button {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  @include media-breakpoint-down(md) {
    .contact-bottom__cta {
      flex-wrap: wrap !important;

      >div {
        width: 100% !important;

        button {
          width: 100%;
          white-space: nowrap;
        }

        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }

  .contact-bottom__cta__left+.contact-bottom__cta__left {
    .lw-button {
      background: none !important;
      border: 1px solid $orange !important;
      color: $orange !important;
    }
  }
}

.myaccount-header__title {
  text-align: left !important;
  color: #FFF !important;

  h3 {
    color: #FFF !important;
  }
}

.categories__row.row {
  display: flex;
}

.account-information-title,
.my-orders-title {
  padding-bottom: calcRem(15px) !important;
  color: #787E8E;
  padding-left: 0 !important;
  margin-left: 0 !important;
  font-size: 1.75rem;
}

.account-information-title {
  font-weight: normal !important
}

.account-card--content {
  .table-total {
    color: $orange;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .table-header {
    font-size: 1rem !important;
    color: $orange;
    font-weight: bold !important
  }
}

.account-card-balance,
.account-card--header {
  font-size: 1rem !important;
  color: #142C48;
  font-weight: bold;

  .account-card-balance {
    font-size: 1rem !important;
    color: #142C48;
    font-weight: bold;
  }

  h4 {
    font-size: 1rem !important;
  }
}

.account-card--header .account-card-balance {
  font-size: 1rem !important;
  color: #142C48;
  font-weight: bold !important
}

.account-summary .card {
  padding: calcRem(15px) 0 0 0 !important;
}

.info-section {
  margin-top: 0 !important;

  h4 {
    font-size: 1.6rem !important;
    color: #142C48 !important;
  }

  .form-control {
    border-radius: 5px !important;
    background: #F9F9F9 !important;
    border: 1px solid #DCDCDC !important;
  }
}

.modal-dialog {
  .form-control {
    border-radius: 5px !important;
    background: #F9F9F9 !important;
    border: 1px solid #DCDCDC !important;
  }
}

.myaccount-summary {
  h3 {
    color: $orange;
  }
}


.page-search {
  background-color: #F0EFEC !important;
  padding: calcRem(60px) 0 calcRem(60px) 0 !important;

  .search-filter {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.dropdown {
  border-radius: 0px !important;
  border: 1px solid #DCDCDC !important;

  .dropdown-toggle {
    border-radius: 0px !important;
  }
}

.lw-pagination {
  img {
    width: 15px;
  }
}

.dd-label {
  color: $orange;
}

.catalogueItem__img {
  border-radius: 0 !important;

  img {
    border-radius: 0 !important;
  }
}

.catalogueItem {
  border: 0 !important;

  .catalogueItem__img {
    border: 0 !important;

    img {
      border: 0 !important;
    }
  }

  .catalogueItem__description {
    text-align: left !important;
    width: 100%;
  }
}

.search-row .lw-pagination {
  justify-content: flex-end;

  >div {
    margin: 0 10px;
  }
}

.search-row {
  .col-lg-4 {
    width: 25% !important;
  }

  @include media-breakpoint-down(md) {
    width: 50% !important;
  }
}

body {
  .breadcumb {
    background: $titleBlue;

    ul {
      li {
        color: #fff;

        span,
        a {
          color: #FFF;

          &.active {
            color: #FFF;
          }
        }
      }
    }
  }
}


.product-card__header__img__name__top {
  text-transform: uppercase !important;
  font-weight: 500 !important;
}

.product-card__header__img__name__bottom {
  display: none !important;
}

.product-card__pricing {
  h3 {
    font-size: 1.3rem;
    color: $black;
  }

  .product-card__pricing__header {
    background: none !important;
    color: $dashboardGrey;
    padding: 0;
    margin: 0;
  }

  .image--list__selected {
    display: flex;
    justify-content: space-between;
  }
}

.product-card-ex__accordion__header,
.product-card__accordion__header {
  background: #F0EFEC !important;
  color: $titleBlue !important;
}

.product-card-ex__accordion__body,
.product-card__accordion__body {
  .inner>.align-items-end {
    flex-wrap: wrap;

    >div {
      width: 100%;
      padding-top: calcRem(25px);

      &:first-child {
        padding-top: 0
      }
    }
  }

  .mobile-cut-summary {
    >div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 1.2rem;

      &+div {
        font-size: 1.4rem;
        font-weight: bold;
        color: $orange;

        .calculation--label {
          font-size: 1.4rem;
          font-weight: bold;
          color: $orange;
        }
      }
    }
  }
}

.image--list__row__active {
  border: 0 !important;

  .image--list__image {
    img {
      border: 1px solid $orange !important;
      padding: 3px !important;
    }
  }
}

.product-card__pricing .product-card__pricing__content {
  padding: 0 !important;
}

.product-card__pricing {
  &.__content {
    margin-top: 0 !important
  }
}

.product-card__pricing .product-card__pricing__content .product-card__pricing__prices {
  width: 100% !important;
}

.list__row {
  .image--list__image img {
    padding: 3px !important;
  }
}

.product-card__accordion__header__title {
  color: $orange;
}

.product-card__pricing .product-card__pricing__view_availability a {
  background: none !important;
  color: #000 !important
}

.colour-red,
.badge.out-of-stock,
.red-circle {
  background: #e22929 !important;
}

.dropdown .badge.colour-orange,
.colour-orange,
.badge.pre-order,
.amber-circle {
  background: #eda310 !important;
  color: #FFF !important;
}

.badge.in-stock {
  background: #4E9A67 !important;

}

.green-circle {
  background: #4CDF82 !important;
}

.product-card__pricing .product-card__pricing__content {
  margin-top: 0 !important;
}

.product-card__pricing .badge {
  padding: 7px 16px !important;
  display: inline-block;
  width: auto;
  border-radius: 20px;
  background: #4E9A67 !important;

  &.pre-order {
    background: #eda310 !important;
  }

  &.out-of-stock {
    background: #e22929 !important;
  }
}

.product-card__pricing__view_availability {
  display: none !important;
}

.product-card__availability_row {
  margin-left: -15px;
  margin-right: -15px;

  >div {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
}

.password-reset {
  h4 {
    color: #142C48;
  }

  .form-control {
    border-radius: 5px !important;
    background: #F9F9F9 !important;
    border: 1px solid #DCDCDC !important;
  }
}

.myaccount {
  padding-top: 0 !important;
}

.product-card__pricing .product-card__pricing__content .product-card__pricing__availability>div {
  flex-wrap: initial !important;
  ;
}

.product-card__pricing .product-card__pricing__content .product-card__pricing__availability {
  width: 100%;
  justify-content: flex-start;

  >div {
    justify-content: flex-start !important;
  }

  a {
    font-size: 0.8rem;
    display: inline-block;
    padding-left: 10px;
    ;
  }
}

.product-card__availability_row>div {
  &:first-child {
    padding-left: 15px !important;

    &+div {
      padding-left: 25px !important;
      border-left: 1px solid #EFEFEF;
    }

  }


}

.product-card-units-pallets .lw-button,
.product-card-ex .lw-button,
.product-card .lw-button {
  border-radius: 6px !important;
}

.product-card__pricing .product-card__pricing__content .product-card__pricing__availability.stock-availability-justification,
.product-card__pricing .product-card__pricing__content .product-card__pricing__availability>div.stock-availability-justification {
  justify-content: space-between !important;
}

.product-card-ex__accordion__header,
.product-card__accordion__header {
  padding: calcRem(15px) calcRem(25px) !important;
}

.product-card-ex__accordion__header__title,
.product-card__accordion__header__title {
  font-size: 15px !important;
}

.image--list {
  border-top: 1px solid #EFEFEF;
}

.orders {
  background: #FFF;
}

.payment__date__info__details__text .dropdown .dropdown-toggle {
  background: #FFF !important;
}

.orders__title h3 {
  font-weight: 500px !important;
}

.payment {
  background: #F8F8F8 !important;

  .payment__date__info~.pt-3 {
    padding-top: 0.5rem !important;
  }

  .text-dark-grey {
    color: $textgrey;
  }

  .form-control {
    background: #FFF !important;
  }

  .cart-btn+div {
    margin-top: 1rem !important;
  }

  .continue-shopping {
    text-decoration: none;
    font-weight: normal !important;
    border: 1px solid #C5C5C5 !important;
    display: block;
    height: calcRem(45px);
    min-width: 100%;
    box-sizing: border-box;
    padding: calcRem(10px) calcRem(20px);
    font-size: $fontSizeSmall;
    text-align: center;
    border-radius: 8px !important;
    line-height: 45px !important;
  }

  .lw-button {
    border-radius: 8px !important;
  }
}

.payment__details {
  background: #E5E6EB;
  border-radius: 18px;
  padding: 25px;

  .text-dark-grey {
    color: $orange;
  }

  svg,
  img {
    display: none !important;
  }
}

.shipping-options {
  h4 {
    text-transform: uppercase;
  }
}

.dashbanner,
.page-header__img {
  background: #f0efec !important
}

.page-header {
  background-color: #F0EFEC !important;
}

.contact-outer {
  background-color: #FFF !important;
  background: linear-gradient(to left, #f7f7f9 50%, #FFF 50%) !important;
}

.contact__left {
  background: #FFF;
  ;

  .form-control {
    background-color: #f7f7f9 !important;
  }

  textarea {
    border: 1px solid #DCDCDC !important;
  }

  label {
    font-weight: normal;
  }
}

.contact__right {
  background-color: #f7f7f9 !important;
}

.contact__right__order {
  padding-top: calcRem(10px);

  .contact__right__title {
    font-weight: normal;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: calcRem(5px) !important;
  }

  p {
    margin-bottom: calcRem(5px) !important;
  }
}

.product-card-units-pallets .product-card-ex__accordion.pallets .calculation--label,
.product-card-ex .product-card-ex__accordion.pallets .calculation--label {
  font-size: 1.4rem;
  font-weight: bold;
  color: #434975;
}

.image--list__selected {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  >.bold {
    font-size: 1.4em !important;
  }
}

.footer {
  p {
    color: $textgrey;
  }
}

.footer__top__first {
  color: $textgrey;

  span {
    b {
      font-size: 1.2rem;
      font-weight: normal;
      color: $orange;
    }
  }

  li {
    a {
      color: $textgrey;
    }
  }
}

.contact-outer .myaccount-header {
  margin: 0 !important;
}

.checkout_item {
  box-shadow: none !important;
  border-bottom: 1px solid $lightgrey;
  ;
}

.checkout_item .checkout_item_img img {
  border-radius: 50%;
}

.orders__search__right {
  span {
    display: block;

    &:last-child {
      color: $black;
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
}

.hero-section,
.products__header {
  padding: 30px 0 30px 0 !important
}

.hero-categories-container .supertitle h4 {
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-stretch: expanded;
  letter-spacing: 2px;
}

.hero-categories-container .supertitle {
  color: $orange;
}

.product-card__pricing h3 {
  font-size: 1.2rem;
}

@include media-breakpoint-down(xl) {

  .product-card__pricing .product-card__pricing__content .product-card__pricing__availability.stock-availability-justification,
  .product-card__pricing .product-card__pricing__content .product-card__pricing__availability>div.stock-availability-justification {
    flex-wrap: wrap !important;
    justify-content: start !important;

  }

  .nav-container .navbarFlex a.nav-link {
    padding: 0 10px !important;
  }
}



@include media-breakpoint-down(lg) {
  .product-card__pricing .product-card__availability_row {
    border: 0 !important;
  }

  .product-card__pricing .product-card__availability_row>div:last-child {
    padding-left: 15px !important;
    border: 0 !important;
  }

  .image--list {
    border-top: 0 !important;
  }

  .nav-container .navbarFlex a.nav-link {
    border-left: none !important;
    padding: 10px 0 !important;
    margin-right: 0 !important;
  }

  .nav-container .navbarFlex a.mobile-hide~.nav-link {
    color: #5d6076 !important;
    text-align: left !important;
    font-size: 1.1rem !important;

    &:after {
      display: none !important;
    }
  }

  .account-information-title {
    padding-top: 30px !important;

    &+.info-section {
      margin-top: 15px !important;

      >.col-lg-6+.col-lg-6 {
        padding-top: 30px;
      }
    }
  }

  .myaccount__content__nav {
    padding-bottom: 20px;
  }

  .grey-container.mt-5 {
    margin-top: 0 !important;

  }

  .products__header__search {
    max-width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.page-search~.container {
  @include media-breakpoint-down(md) {
    .row {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .search-row:first-child,
    .search-row:last-child {
      width: 100% !important;

      .col-md-4,
      .col-md-8 {
        width: 50% !important;

        @include media-breakpoint-down(sm) {
          width: 100% !important;
        }
      }

      .col-md-4 {
        >.d-flex {
          justify-content: center !important;
        }
      }

      .lw-pagination__pill:nth-child(2),
      .lw-pagination__pill:nth-child(3) {
        display: block !important;
      }

      @include media-breakpoint-down(sm) {
        .lw-pagination {
          justify-content: center !important;
        }

        .lw-pagination__pill:nth-child(4) {
          display: block !important;
        }
      }
    }

    .search-row:not(:first-child):not(:last-child) {
      width: 100% !important;

      .col-lg-4 {
        width: 50% !important;

        @include media-breakpoint-down(sm) {
          width: 50% !important;
          .catalogueItem{
            min-height: calcRem(220px);
          }
          .catalogueItem__img{
            img{
              height:13rem !important;
            }      
          }
        }
      }
    }
  }
}

.actual-search-page h4 {
  text-align: center !important;
  text-transform: uppercase !important;
  font-size: 0.9rem;
  padding-bottom: calcRem(15px) !important;
}

.actual-search-page .products__header__title {
  text-transform: uppercase !important;
  font-size: 1.3rem !important;
}

.page-container .login-container .input-container {
  padding-right: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.CookieConsent {
  color: #FFF !important;

  div,
  p {
    color: #FFF !important;
  }
}

#rcc-confirm-button {
  background: $orange !important;
}

.continue-shopping,
.lw-button {
  max-height: 60px;
}


@include media-breakpoint-down(sm) {
  .myaccount__content__nav {
    padding-bottom: 20px;
  }

  .myaccount-container {
    padding: 0 calcRem(40px) !important;
  }
 
}

@include media-breakpoint-down(md) {
  .change-password-form {
    >div {
      width: 100% !important;
    }
  }
}
body{
  .categories .categories__title{
    margin-bottom: calcRem(20px);
    padding-bottom: 0;
    h3{
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  .categories .category-counts {
      float: right;
      font-size: 0.9rem;
      color: #5d6076;
  }
  @include media-breakpoint-down( md ){
    .category-counts{
      display: none !important;
    }
  }
}

.navbar-brand{
  img{
    max-width: 110px;
  }
}

.pricelists{
  .pricelist-back-link{
    cursor: pointer;
  }
}


.pricelists{
  .myaccount-header {
      height: auto;
      margin-bottom: 0;
      margin-bottom: 40px;
  }

}

.page-container.login-outer .row {
  align-items: flex-start;
  .login-container{
    padding-top:30px;
  }
  
}
@include media-breakpoint-down( md ){
  .pricelists{
    .myaccount-header {
        height: auto;
        margin-bottom: 0;
    }
    .nav-link {
        display: block;
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
        font-size: var(--bs-nav-link-font-size);
        font-weight: var(--bs-nav-link-font-weight);
        color: var(--bs-nav-link-color);
        text-decoration: none;
        background: none;
        border: 0;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    }
  }

}

.clearance-outer{
  .page-search{
    background: none !important;
    padding-top: 20px !important;
  }
}

.pricelists .myaccount__content__nav ul li a {
  margin-bottom: 0 !important;
}

.modal{
  z-index: 999999 !important;
}
.modal-backdrop{
  z-index: 99998 !important;
}